<template>
  <div class="direct-cities-links-wrapper">
    <h2>Hotels in Most Popular Cities</h2>
    <client-only>
      <div class="direct-cities-links" v-if="!isMobile">
        <nuxt-link
          :to="'/hotels/' + city.slug"
          class="direct-city-item"
          v-for="(city ,idx) in cities"
          :key="idx">
          <img
            height="119"
            width="228"
            v-lazy="'/img/direct-cities/' + city.image"
            :alt="city.name">
          <span class="name">{{city.name}}</span>
        </nuxt-link>
      </div>
    </client-only>

    <!-- mobile carousel -->
    <client-only>
      <div v-if="isMobile">
        <v-carousel
          class="mobile-cities-carousel"
          :show-arrows="false"
          hide-delimiter-background
          :delimeter-icon="'mdi-circle'"
          :height="400"
          light>
          <v-carousel-item
            v-for="(citiesGroup ,idx) in citiesGroups"
            :key="idx"
          >
            <div class="direct-cities-links">

              <nuxt-link
                v-for="(city, index) in citiesGroup" :key="index"
                :to="'/hotels/' + city.slug"
                class="direct-city-item">
                <img
                  height="119"
                  width="228"
                  v-lazy="'/img/direct-cities/' + city.image"
                  :alt="city.name">
                <span class="name">{{city.name}}</span>
              </nuxt-link>
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
    </client-only>
  </div>
</template>

<script>
export default {
  name: 'DirectCitiesLinks',
  data() {
    return {
      cities: [
        {
          name: 'New York',
          slug: 'us-ny-new-york',
          image: 'new-york.jpeg'
        },
        {
          name: 'Los Angeles',
          slug: 'us-ca-los-angeles',
          image: 'los-angeles.jpeg'
        },
        {
          name: 'Las Vegas',
          slug: 'us-nv-las-vegas',
          image: 'las-vegas.jpeg'
        },
        {
          name: 'Orlando',
          slug: 'us-fl-orlando',
          image: 'orlando.jpeg'
        },
        {
          name: 'Miami',
          slug: 'us-fl-miami-beach',
          image: 'miami.jpeg'
        },
        {
          name: 'San Francisco',
          slug: 'us-ca-san-francisco',
          image: 'san-francisco.jpeg'
        },
        {
          name: 'Chicago',
          slug: 'us-il-chicago',
          image: 'chicago.jpeg'
        },
        {
          name: 'San Diego',
          slug: 'us-ca-san-diego',
          image: 'san-diego.jpeg'
        },
        {
          name: 'Washington',
          slug: 'us-dc-washington-dc',
          image: 'washington.jpeg'
        },
        {
          name: 'Honolulu',
          slug: 'us-hi-honolulu',
          image: 'honolulu.jpeg'
        },
        {
          name: 'New Orleans',
          slug: 'us-la-new-orleans',
          image: 'new-orleans.jpeg'
        },
        {
          name: 'Boston',
          slug: 'us-ma-boston',
          image: 'boston.jpeg'
        },
        {
          name: 'Atlanta',
          slug: 'us-ga-atlanta',
          image: 'atlanta.png'
        },
        {
          name: 'Dallas',
          slug: 'us-tx-dallas',
          image: 'dallas.jpeg'
        },
        {
          name: 'Denver',
          slug: 'us-co-denver',
          image: 'denver.jpeg'
        },
        {
          name: 'Seattle',
          slug: 'us-wa-seattle',
          image: 'seattle.jpeg'
        },
        {
          name: 'Phoenix',
          slug: 'us-az-phoenix',
          image: 'phoenix.jpeg'
        },
        {
          name: 'Philadelphia',
          slug: 'us-pa-philadelphia',
          image: 'philadelphia.jpeg'
        },
        {
          name: 'Nashville',
          slug: 'us-tn-nashville',
          image: 'nashville.jpeg'
        },
        {
          name: 'Houston',
          slug: 'us-tn-nashville',
          image: 'houston.jpeg'
        },
      ]
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs
    },
    citiesGroups() {
      const GROUP_SIZE = 6

      return this.cities.map((_, index) => this.cities.slice(index * GROUP_SIZE, index * GROUP_SIZE + GROUP_SIZE))
        .filter(group => group.length > 0)
    }
  }
}
</script>

<style src="./_direct-cities-links.scss" lang="scss"></style>
